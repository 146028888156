import React from "react";
import IndexPage from "./index"

const IndexPageFR = () => (
  <IndexPage i18n="fr"/>
);

export default IndexPageFR;


